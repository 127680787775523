import qs from 'qs'
import Vue from 'vue'
import axios from 'axios'
import {
	Loading
} from 'element-ui';
import router from '../router'
var vm = new Vue({
	router
})
let loadingCount = 0;
let loading;
let oneget = 0
const startLoading = () => {
	loading = Loading.service({
		lock: true,
		text: '安全检测中……',
		background: 'rgba(0, 0, 0, 0.0)'
	});
};

const endLoading = () => {
	loading.close();
};

const showLoading = () => {
	if (loadingCount === 0) {
		startLoading();
	}
	loadingCount += 1;
};

const hideLoading = () => {
	if (loadingCount <= 0) {
		return;
	}
	loadingCount -= 1;
	if (loadingCount === 0) {
		endLoading();
	}
};

//BaseURL
//axios.defaults.baseURL = '/api';
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
//请求拦截
axios.interceptors.request.use(
	function(config) {
		config.headers.Authorization = localStorage.getItem('token')
		//config.headers.common['Pledge-SessionId'] = sessionStorage.getItem("sessionId")
		return config;
	},
	function(error) {
		// 对请求错误做些什么
		return Promise.reject(error);
	}
);
//响应拦截
axios.interceptors.response.use(function(response) {
	
	// 处理响应数据
	if (response.status === 200) {
		hideLoading();
		return Promise.resolve(response);
	} else {
		hideLoading();
		return Promise.reject(response);
	}
}, function(error) {
	// 处理响应失败
	return Promise.reject(error);
});

//get请求
export function get(url, params, noLoading) {
	if (url == "wechat/read_status"||url=="order/checkpay") {
		if (!noLoading) {
			showLoading();
		}
	}
	return new Promise((resolve, reject) => {
		axios.get(url, {
				params
			})
			.then(res => {
				if (res.data.error == "443") {
					if (oneget == 0) {
						localStorage.removeItem("whichlogin")
						localStorage.removeItem("token")
						localStorage.removeItem("name")
						localStorage.removeItem("img")
						localStorage.removeItem("is_auth")
						location.reload()
						oneget = 1
					}
				}
				resolve(res.data);
			}).catch(err => {
				reject(err.data)
			})
	});
}

//del请求
export function del(url, params) {
	// showLoading();
	return new Promise((resolve, reject) => {
		axios.delete(url, {
				params
			})
			.then(res => {
				resolve(res.data);
			}).catch(err => {
				reject(err.data)
			})
	});
}

//post请求
//isLoading 是否显示加载面
export function post(url, params, noLoading) {
	if  (url == "wechat/read_status"||url=="order/checkpay") {
		if (!noLoading) {
			showLoading();
		}
	}
	return new Promise((resolve, reject) => {
		axios.post(url, params)
			.then(res => {
				if (res.data.error == "443") {
					if (oneget == 0) {
						localStorage.removeItem("whichlogin")
						localStorage.removeItem("token")
						localStorage.removeItem("name")
						localStorage.removeItem("img")
						localStorage.removeItem("is_auth")
						location.reload()
						oneget = 1
					}
				}
				resolve(res.data);
			})
			.catch(err => {
				reject(err.data)
			})
	});
}
