import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);  
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
export default new Router({
	routes: [{
			path: '/',
			component: () => import( /* webpackChunkName: "" */ '../components/common/Home.vue'),
			meta: {
				title: '自述文件'
			},
			children: [{
					path: '/home',
					component: () => import( /* webpackChunkName: "home" */ '../components/page/home/home.vue'),
					meta: {
						title: '首页'
					}
				},
				{
					path: '/my',
					component: () => import( /* webpackChunkName: "my" */ '../components/page/my/my.vue'),
					meta: {
						title: '个人中心'
					}
				},
				{
					path: '/vip',
					component: () => import( /* webpackChunkName: "vip" */ '../components/page/my/vip.vue'),
					meta: {
						title: '会员中心'
					}
				},
				{
					path: '/message',
					component: () => import( /* webpackChunkName: "vip" */ '../components/page/my/message.vue'),
					meta: {
						title: '消息中心'
					}
				},
				{
					
					path: '/commit',
					component: () => import( /* webpackChunkName: "commit" */ '../components/page/my/commit.vue'),
					meta: {
						title: '评论'
					}
				},
				{
					path: '/buycar',
					component: () => import( /* webpackChunkName: "buycar" */ '../components/page/my/buycar.vue'),
					meta: {
						title: '购物车'
					}
				},
				{
					path: '/search',
					component: () => import( /* webpackChunkName: "search" */ '../components/page/home/search.vue'),
					meta: {
						title: '搜索结果'
					}
				},
				{
					path: '/classTop',
					component: () => import( /* webpackChunkName: "classTop" */ '../components/page/home/classTop.vue'),
					meta: {
						title: '置顶管理'
					}
				},
				// 机构入驻
				{
					path: '/enter',
					component: () => import( /* webpackChunkName: "enter" */ '../components/page/organize/enter.vue'),
					meta: {
						title: '入驻首页'
					}
				},
				{
					path: '/stepOne',
					component: () => import( /* webpackChunkName: "stepOne" */ '../components/page/organize/stepOne.vue'),
					meta: {
						title: '步骤一'
					}
				},
				{
					path: '/stepTwoPerson',
					component: () => import( /* webpackChunkName: "stepTwoPerson" */
						'../components/page/organize/stepTwoPerson.vue'),
					meta: {
						title: '步骤二-个人'
					}
				},
				{
					path: '/stepTwoOrganize',
					component: () => import( /* webpackChunkName: "stepTwoOrganize" */
						'../components/page/organize/stepTwoOrganize.vue'),
					meta: {
						title: '步骤二-机构'
					}
				},
				{
					path: '/stepThree',
					component: () => import( /* webpackChunkName: "stepThree" */ '../components/page/organize/stepThree.vue'),
					meta: {
						title: '步骤三'
					}
				},

				// 课程详情——支付
				{
					path: '/payConfirm',
					component: () => import( /* webpackChunkName: "payConfirm" */ '../components/page/course/pay/payConfirm.vue'),
					meta: {
						title: '确认支付'
					}
				},
				{
					path: '/payWay',
					component: () => import( /* webpackChunkName: "payWay" */ '../components/page/course/pay/payWay.vue'),
					meta: {
						title: '支付方式'
					}
				},
				{
					path: '/paySuccess',
					component: () => import( /* webpackChunkName: "paySuccess" */ '../components/page/course/pay/paySuccess.vue'),
					meta: {
						title: '支付成功'
					}
				},
				// 课程详情——机构主页
				{
					path: '/organizeInfo',
					component: () => import( /* webpackChunkName: "organizeInfo" */ '../components/page/course/organizeInfo.vue'),
					meta: {
						title: '机构主页'
					}
				},
				// 课程详情——付费
				{
					path: '/courseDetail',
					component: () => import( /* webpackChunkName: "courseDetail" */ '../components/page/course/courseDetail.vue'),
					meta: {
						title: '课程详情'
					}
				},
				// 微专业课程
				{
					path: '/microcoursedetail',
					component: () => import( /* webpackChunkName: "microcoursedetail" */
						'../components/page/course/microcoursedetail.vue'),
					meta: {
						title: '课程详情'
					}
				},
			]
		},
		{
			path: '/help',
			component: () => import( /* webpackChunkName: "login" */ '../components/page/help.vue'),
			meta: {
				title: '帮助'
			}
		},
		{
			path: '/xieyi',
			component: () => import( /* webpackChunkName: "xieyi" */ '../components/page/xieyi.vue'),
			meta: {
				title: '协议'
			}
		},
		{
			path: '*',
			redirect: '/home',
		},
	]
});
