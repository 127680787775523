import Vue from 'vue';
import App from './App.vue';
import router from './router';
import ElementUI from 'element-ui';
import VueI18n from 'vue-i18n';
import {
	messages
} from './components/common/i18n';
import 'element-ui/lib/theme-chalk/index.css'; // 默认主题
import './assets/css/icon.css';
import './components/common/directives';
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)
import Base64 from './utils/base64.js'
Vue.prototype.$Base64 = Base64;
import 'babel-polyfill';
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css' // 引入样式
import 'vue-video-player/src/custom-theme.css' // 引入样式
import 'videojs-flash'
Vue.use(VueVideoPlayer)

import {
	get,
	post
} from './utils/request'
import './utils/rem'
//main.js中
import {
	setCookie,
	getCookie,
	delCookie
} from './utils/cookie.js'
Vue.prototype.$cookieStore = {
	setCookie,
	getCookie,
	delCookie
}
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.config.productionTip = false;
Vue.use(VueI18n);
Vue.use(ElementUI, {
	size: 'small'
});
var language
var JsSrc = (navigator.language || navigator.browserLanguage).toLowerCase();
if (JsSrc.indexOf('zh') >= 0) {
	// 假如浏览器语言是中文
	console.log("中文")
	language = 'zh'
} else if (JsSrc.indexOf('en') >= 0) {
	// 假如浏览器语言是英文
	language = 'en'
	console.log("英文")
} else {
	// 假如浏览器语言是其它语言
}
const i18n = new VueI18n({
	locale: language,
	messages
});
if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
	window.location.href = "https://ykt.qdy2020.com/m";
}
//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
	document.title = `${to.meta.title} `;
	const token = localStorage.getItem('token');
	if (token) {
		next()
		return
	}
	next()
});

new Vue({
	router,
	i18n,
	render: h => h(App),
	mounted() {
		document.dispatchEvent(new Event('render-event'))
	}
}).$mount('#app');

//lfs引入了seo优化能力，能让百度google大厂实时捕获数据首先推荐
